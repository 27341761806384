import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    camera: null,
  },
  mutations: {
    SET_USER(state, user) {
        state.user = user;
    },
    SET_CAMERA(state, camera) {
        state.camera = camera;
    },
    RESET_USER(state) {
        state.user = null; 
    },
  },
  actions: {
    setUser({ commit }, user) {
        commit('SET_USER', user);
    },
    setCamera({ commit }, camera) {
        commit('SET_CAMERA', camera);
    },
    logout({ commit }) {
        commit('RESET_USER');
    },
  },
  getters: {
    // Check if a user is logged in
    isLoggedIn(state) {
        return state.user !== null;
    },
    // Get the current user
    currentUser(state) {
        return state.user;
    },
    userID(state) {
        return state.user ? state.user.uid : '';
    },
    cameraID(state) {
        return state.camera ? state.camera.id : '';
    }
  }
});

export default store;
