import Vue from 'vue'
import App from './App.vue'

import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import router from './router';
import VueRouter from 'vue-router';

import store from './store';

import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'

Vue.use(Buefy);
Vue.use(VueRouter);

Vue.component('v-select', vSelect);

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDTslaRmyZBUYQlsvi97gBLIJ2EEY4llN4",
  authDomain: "reefos-community.firebaseapp.com",
  projectId: "reefos-community",
  storageBucket: "reefos-community.appspot.com",
  messagingSenderId: "889761361546",
  appId: "1:889761361546:web:4ddeb278c3f472afe88773"
};

// Initialize Firebase
initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore()
export default db

Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App),
  router,
}).$mount('#app')
