import VueRouter from 'vue-router';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import store from './store';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/components/LoginPage.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/components/Dashboard.vue'),
    meta: {
      requiresAuth: true 
    }
  },
  {
    path: '/species',
    name: 'Species List',
    component: () => import('@/components/SpeciesPage.vue'),
    meta: {
      requiresAuth: true 
    }
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  onAuthStateChanged(getAuth(), (user) => {
    if (user) {
        // Dispatch an action to your Vuex store to set the user
        store.dispatch('setUser', user);
        if (!requiresAuth) {
            next('/dashboard');
        } else {
            next();
        }
    } else {
        if (requiresAuth) {
            next('/');
        } else {
            // When there's no user and the route does not require auth
            next();
        }
    }
  });
});

export default router;
