<template>
	<div id="app">
		<router-view/>
	</div>
</template>

<script>

export default {
	name: 'App',
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	background-color: black;
}

html, body{
	height: 100vh;
	width: 100vw;
	background-color: black;
}

p, label {
	font-family: Lato;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px;
	color: white;
	text-align: left;
}

h1 {
	font-family: Lato;
	font-size: 60px;
	font-style: normal;
	font-weight: 900;
	line-height: 72px;
	letter-spacing: -1.2px;
	text-transform: uppercase;
}

h2 {
	font-family: Lato;
	font-size: 48px;
	font-style: normal;
	font-weight: 900;
	line-height: 60px; 
	letter-spacing: -0.96px;
	text-transform: uppercase;
	text-align: left
}
</style>



